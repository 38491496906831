import React from 'react'

function Header() {
  return (
    <div id="home" style={{ 
        backgroundAttachment: 'fixed',
        backgroundImage: `url('/images/header_bg-2.png')`,
        backgroundSize: '',
        backgroundPosition: 'top',
        }}>

            <div className='py-[85px] relative z-10 lg:py-[170px] 2xl:max-w-[1400px] gap-y-[50px] mx-auto px-4 flex flex-col lg:flex-row justify-between items-center'>
                <div className='flex flex-col items-center lg:items-start gap-y-[50px]'>
                    <h1 className='text-[50px] xl:text-[60px] text-white leading-[1.25] items-center lg:text-left title'>Construction solution <br />
                    for <span className='text-[#F2C94C]'>everyone</span></h1>
                    <p className='text-[14px] xl:text-[17px] leading-[1.55] max-w-[550px] xl:max-w-[750px] text-white items-center lg:text-left font-bold'>Our mission is to build lasting relationships through excellence in construction, delivering unparallel craftsmanship, innovative design solutions and staying within budget.</p>
                    <a href="#about" className="text w-[170px] h-[50px] text-[16px] rounded-[5px] flex flex-col items-center justify-center  text-white bg-[#191A19]
        hover:bg-white hover:text-[#191A19] transition-all
        cursor-pointer
        ">Learn More</a>
                </div>
                <div className='md:w-[500px] w-full h-full border border-white/10 p-8 md:h-[560px] bg-[#191A19]/90 lg:bg-[#191A19]/60 px-[70px] backdrop-blur-md rounded-[8px] flex flex-col items-center justify-center'>
                    <div className='flex flex-col items-center justify-center space-y-[36px] gap-y-[36px]'>
                    <div className='flex flex-row items-center  gap-x-[14px]'>
                        <img className='w-[60px]' src="/images/home.png"/>
                        <div className='flex flex-col items-start'>
                            <p className='text-[16px] text-left font-bold text-[#F2C94C] title'>New Construction - Residential/Commerical </p>
                            <p className='text-[12px] text-white leading-[1.3] text-left'>No matter if this is your first new construction or you are a seasoned investor, our team will help you through the full plan scope from pre slab work, all the way through a full turn key project from design, build to living</p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-x-[14px]'>
                        <img className='w-[60px]' src="/images/crane.png"/>
                        <div className='flex flex-col items-start'>
                            <p className='text-[16px] font-bold text-[#F2C94C] text-left title'>Major Renovations - Residential/Commercial</p>
                            <p className='text-[12px] text-white leading-[1.3] text-left'>Are you looking to gut a property interior or add an addition? Get with one of our MCC sales reps to gather that information to help set you a budget</p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-x-[14px]'>
                        <img className='w-[60px]' src="/images/engineer.png"/>
                        <div className='flex flex-col items-start'>
                            <p className='text-[16px] text-left font-bold text-[#F2C94C] title'>Architectural/Engineering Designs</p>
                            <p className='text-[12px] text-white leading-[1.3] text-left'>From the thought process to the design work, MCC has it here for you. Our in-house licensed Architects and Engineers allow us to collaborate closely with our clients to pinpoint their wants and needs. </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Header