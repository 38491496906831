
"use client";

import { Navbar } from "flowbite-react";

export function Nav() {
  return (
    <Navbar className="lg:px-[170px] py-2" fluid rounded>
      <Navbar.Brand  href="https://flowbite-react.com">
        <img src="/images/white-logo.jpg" className="w-[120px] lg:w-[150px] mr-3" alt="Monument Logo" />
        {/* <span className="text-[20px] font-medium hidden xl:block title ">MONUMENT CONSTRUCTION.</span> */}
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="">
        {/* <Navbar.Link className="text-[16px] flex flex-col justify-center items-center h-full"href="#" >
          Home
        </Navbar.Link  > */}
        <Navbar.Link className="text-[16px] flex flex-col justify-center items-center h-full"href="#about">
          About
        </Navbar.Link>
        <Navbar.Link className="text-[16px] flex flex-col justify-center items-center h-full" href="#projects">Projects</Navbar.Link>
        {/* <Navbar.Link href="#">Contact</Navbar.Link> */}
        <a href="#contact" className="text w-[170px] mx-auto h-[50px] text-[16px] rounded-[5px] flex flex-col items-center justify-center border border-[#191A19] text-[#191A19]
        hover:bg-black hover:text-white transition-all
        cursor-pointer
        ">Contact us</a>
      </Navbar.Collapse>
    </Navbar>
  );
}
