import React from 'react'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

function Projects() {
    const [openOne, setOpenOne] = React.useState(false);
    const [openTwo, setOpenTwo] = React.useState(false);
    const [openThree, setOpenThree] = React.useState(false);
    const [openFour, setOpenFour] = React.useState(false);
    const [openFive, setOpenFive] = React.useState(false);
  return (

    <div className='bg-[#F6F8F7]/0'>
      {/* One */}
      <Lightbox
        open={openOne}
        close={() => setOpenOne(false)}
        slides={[
          { src: "/images/office/office-1.jpg" },
          { src: "/images/office/office-2.jpg" },
          { src: "/images/office/office-3.jpg" },
          { src: "/images/office/office-4.jpg" },
          { src: "/images/office/office-5.jpg" },
          { src: "/images/office/office-6.jpg" },
          { src: "/images/office/office-7.jpg" },
          { src: "/images/office/office_thumb.jpg" },
        ]}
      />
      {/* Two */}
      <Lightbox
        open={openTwo}
        close={() => setOpenTwo(false)}
        slides={[
          
          { src: "/images/stone_ga/stone_ga-1.jpg" },
          { src: "/images/stone_ga/stone_ga-2.jpg" },
          { src: "/images/stone_ga/stone_ga-3.jpg" },
          { src: "/images/stone_ga/stone_ga-4.jpg" },
          { src: "/images/stone_ga/stone_ga-5.jpg" },
          { src: "/images/stone_ga/stone_ga-6.jpg" },
          { src: "/images/stone_ga/stone_ga-7.jpg" },
          { src: "/images/stone_ga/stone_thumb.jpg" },
        ]}
      />
      {/* Three */}
      <Lightbox
        open={openThree}
        close={() => setOpenThree(false)}
        slides={[
         
          { src: "/images/alp_ga/alp-1.jpg" },
          { src: "/images/alp_ga/alp-2.jpg" },
          { src: "/images/alp_ga/alp-3.jpg" },
          { src: "/images/alp_ga/alp-5.jpg" },
          { src: "/images/grid-img.png" },
        ]}
      />
      {/* Four */}
      <Lightbox
        open={openFour}
        close={() => setOpenFour(false)}
        slides={[
          
          { src: "/images/hoschton_ga/hoschton-2.jpg" },
          { src: "/images/hoschton_ga/hoschton-3.jpg" },
          { src: "/images/hoschton_ga/hoschton-4.jpg" },
          { src: "/images/hoschton_ga/hoschton-5.jpg" },
          { src: "/images/hoschton_ga/hoschton-6.jpg" },
          { src: "/images/grid-img-2.jpeg" },
        ]}
      />
      {/* Five */}
      <Lightbox
        open={openFive}
        close={() => setOpenFive(false)}
        slides={[
            
            { src: "/images/cumming_ga/cumming_1.jpg" },
            { src: "/images/cumming_ga/cumming_2.jpg" },
            { src: "/images/cumming_ga/cumming_3.jpg" },
            { src: "/images/cumming_ga/cumming_4.jpg" },
            { src: "/images/cumming_ga/cumming_5.jpg" },
            { src: "/images/cumming_ga/cumming_6.jpg" },
            { src: "/images/cumming_ga/cumming_7.jpg" },
            { src: "/images/cumming_ga/cumming_8.jpg" },
            { src: "/images/thumb.jpg" },
        ]}
      />
<div id="projects" className='py-[85px] lg:py-[120px] 2xl:max-w-[1400px]  mx-auto px-4 flex flex-col justify-center items-center'>
    <p className='text-[#F2C94C] text-[14px] mb-[12px]'>OUR PROJECTS</p>
    <h6 className='title text-[48px] text-[#191A19] mb-[25px] text-center lg:text-left'>Our Featured Projects</h6>
    <div className=" flex flex-col items-center xl:grid grid-cols-3 grid-rows-2 gap-4">
    <div className="row-span-2 group col-start-1 overflow-hidden relative rounded-[8px] w-[380px] h-[660px]">
        <img className='h-full object-cover' src="/images/office/office_thumb.jpg"/>
        {/* overlay with job info.. */}
        <div onClick={() => setOpenOne(true)} className="h-[660px] px-4 pb-[24px] flex flex-col justify-end group-hover:translate-y-[0] translate-y-[190%] transition-all w-[380px] bg-gradient-to-b from-[#191A19]/0 to-[#191A19] top-0 absolute">
            <h6 className='title font-bold text-white text-[18px] text-left mb-[24px]'>Major Remodel in 
 <br /> Cumming, GA</h6>
            <p className='text-[12px] max-w-[280px] text-left mb-[12px] text-white'>Once an old dark office space, with walls everywhere, to now a fun modern employee loving, digital workspace playground for this company! </p>
            <div className='flex flex-row items-center gap-x-4'>
                <img className='w-[32px]' src="/images/engineer.png"/>
                <p className='text-[12px] text-left text-[#F2C94C] title font-bold'>7500sf Commercial workspace playground for a merchant services corporation</p>
            </div>
        </div>
    </div>
    <div onClick={() => setOpenTwo(true)} className='w-[380px] relative group h-[320px] overflow-hidden rounded-[8px]' ><img className='w-[380px] h-[320px]' src="/images/stone_ga/stone_thumb.jpg"/>
           {/* overlay with job info.. */}
        <div className="h-[320px] px-4 pb-[24px] flex flex-col justify-end group-hover:translate-y-[0] translate-y-[190%] transition-all w-[380px] bg-gradient-to-b from-[#191A19]/0 to-[#191A19] top-0 absolute">
            <h6 className='title font-bold text-white text-[18px] text-left mb-[24px]'>New Construction Home in <br /> Stone Mountain, GA
</h6>
            <p className='text-[12px] max-w-[280px] text-left mb-[12px] text-white'>A beautiful 2 car garage, 3br, 3ba new construction home for a great first time home buyer  </p>
            <div className='flex flex-row items-center gap-x-4'>
                <img className='w-[32px]' src="/images/engineer.png"/>
                <p className='text-[12px] text-[#F2C94C] title font-bold'>Foundation</p>
            </div>
        </div>
    </div>
    <div onClick={() => setOpenThree(true)} className='w-[380px] relative group h-[320px] overflow-hidden rounded-[8px]' ><img className='w-[380px] h-[320px]' src="/images/grid-img.png"/>
           {/* overlay with job info.. */}
        <div className="h-[320px] px-4 pb-[24px] flex flex-col justify-end group-hover:translate-y-[0] translate-y-[190%] transition-all w-[380px] bg-gradient-to-b from-[#191A19]/0 to-[#191A19] top-0 absolute">
            <h6 className='title font-bold text-white text-[18px] text-left mb-[24px]'>Interior Renovation/Design <br /> Alpharetta, GA</h6>
            <p className='text-[12px] max-w-[280px] text-left mb-[12px] text-white'>4 story interior townhome remodel in Alpharetta </p>
            <div className='flex flex-row items-center gap-x-4'>
                <img className='w-[32px]' src="/images/crane.png"/>
                <p className='text-[12px] text-[#F2C94C] title font-bold'>3980sf 4 story townhome</p>
            </div>
        </div>
    </div>
    <div onClick={() => setOpenFour(true)} className="col-start-2 relative group w-[380px] overflow-hidden rounded-[8px] h-[320px]"><img className='w-[380px] h-[320px]' src="/images/grid-img-2.jpeg"/>
           {/* overlay with job info.. */}
        <div className="h-[320px] px-4 pb-[24px] flex flex-col justify-end group-hover:translate-y-[0] translate-y-[190%] transition-all w-[380px] bg-gradient-to-b from-[#191A19]/0 to-[#191A19] top-0 absolute">
            <h6 className='title font-bold text-white text-[18px] text-left mb-[24px]'>Finished Clothing Store in  <br /> Hoschton, GA</h6>
            <p className='text-[12px] max-w-[280px] text-left mb-[12px] text-white'>Major interior renovation of old warehouse to new clothing store in Hoschton, GA
 </p>
            <div className='flex flex-row items-center gap-x-4'>
                <img className='w-[32px]' src="/images/crane.png"/>
                <p className='text-[12px] text-[#F2C94C] title font-bold text-left'>5,600sf new clothing store with warehouse and full break room area in Hoschton, GA</p>
            </div>
        </div>
    </div>
    <div onClick={() => setOpenFive(true)} className="col-start-3 relative group w-[380px] overflow-hidden rounded-[8px] h-[320px]"><img className='w-[380px] h-[320px]' src="/images/thumb.jpg"/>
           {/* overlay with job info.. */}
        <div className="h-[320px] px-4 pb-[24px] flex flex-col justify-end group-hover:translate-y-[0] translate-y-[190%] transition-all w-[380px] bg-gradient-to-b from-[#191A19]/0 to-[#191A19] top-0 absolute">
            <h6 className='title font-bold text-white text-[18px] text-left mb-[24px]'>Addition Renovation in <br /> Cumming GA</h6>
            <p className='text-[12px] max-w-[280px] text-left mb-[12px] text-white'>A new 4 car garage addition in Cumming, GA. Check out the complete project</p>
            <div className='flex flex-row items-center gap-x-4'>
                <img className='w-[32px]' src="/images/crane.png"/>
                <p className='text-[12px] text-[#F2C94C] title font-bold'>1680sf Addition Renovation</p>
            </div>
        </div>
    </div>
</div> 
</div>
</div>
  )
}

export default Projects
