import logo from './logo.svg';
import './App.css';
import { Nav } from './components/Nav';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import SubFooter from './components/Subfooter';
import Contact from './components/Contact';
import { Foot } from './components/Foot';

function App() {
  return (
    <div className="App">
      <Nav/>
      <Header/>
      <About/>
      <Projects/>
      <SubFooter/>
      <Contact/>
      <Foot/>
    </div>
  );
}

export default App;
