
"use client";

import { Footer } from "flowbite-react";
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from "react-icons/bs";

export function Foot() {
  return (
    <Footer container>
      <div className="w-full">
        <div className="grid w-full justify-center gap-2 sm:flex sm:justify-center md:flex md:grid-cols-1">
          <div className="flex flex-col items-start gap-y-2">
            <Footer.Brand
            className="w-[90px] h-[97px]"
              src="/images/logo.jpg"
              alt="Flowbite Logo"
              name="Flowbite"
            />
            <p className="text-[20px] title text-[#191A19]">Our Company</p>
            <p className="text-[14px] max-w-[550px] leading-[1.2] text-left text-[#191A19]">Our mission is to build lasting relationships through excellence in construction, delivering unparallel craftsmanship, innovative design solutions and staying within budget.</p>
          </div>
          <div className="grid grid-cols-1 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-8">
            <div>
              <Footer.Title className="flex flex-col items-start" title="Office" />
              <Footer.LinkGroup col>
                <p className="text-[14px] max-w-[550px] leading-[1.2] text-left text-[#191A19]"> 8735 dunwoody place Atlanta GA 30350</p>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title className="flex flex-col items-start" title="Contact" />
              <Footer.LinkGroup col>
              <p className='  lg:text-[14px]  lg:max-w-[550px] leading-[1.2] text-left text-[#191A19]'>Email: sales@monumentconstructionco.com</p>
              <p className='text-[14px] lg:max-w-[550px] leading-[1.2] text-left text-[#191A19]'>Phone: 404.912.8516</p>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title className="flex flex-col items-start" title="Links" />
              <Footer.LinkGroup col>
                <a className="text-left" href="#home">Home</a>
                <a className="text-left" href="#about">About</a>
                <a className="text-left" href="#projects">Projects</a>
                <a className="text-left" href="#contact">Contact</a>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="#" by="Monument Construction™" year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon href="#" icon={BsFacebook} />
            <Footer.Icon href="#" icon={BsInstagram} />
            <Footer.Icon href="#" icon={BsTwitter} />
          </div>
        </div>
      </div>
    </Footer>
  );
}
