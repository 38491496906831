import React from 'react'

function Contact() {
  return (
    <div id="contact" style={{ 
        backgroundAttachment: 'fixed',
        backgroundImage: `url('/images/contact-bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        }}>
            <div className='py-[85px] lg:py-[100px] 2xl:max-w-[1400px] gap-y-[50px] mx-auto px-4 flex flex-col xl:flex-row justify-between items-baseline'>
                <div className='flex flex-col max-w-[900px] items-center p-[40px] lg:items-start rounded-[15px] border border-white/10 bg-[#191A19]/90 lg:bg-[#191A19]/60 backdrop-blur-md '>
                    <p className='title text-white mb-[24px] text-[24px]'>Request A Quote</p>
                    <p className='text-[16px] text-white text-left'>Complete control over products allow us to our customers the best quality
                    prices and services. We take great pride in everything that we do in Monument Construction </p>
                    <form
                      action="https://formspree.io/f/xleqylya"
                      method="POST"
                     className='w-full mt-[18px] space-y-[18px]'>
                        <div className='flex w-full flex-row items-center gap-x-[18px]'>
                            <input required name="name" placeholder='name' className='bg-white text-[20px] flex flex-col items-start text-[] h-[56px] w-full rounded-[10px]' type="text" />
                            <input required name="email" placeholder='email' className='bg-white text-[20px] flex flex-col items-start text-[] h-[56px] w-full rounded-[10px]' type="email" />
                        </div>
                        <input required name="phone" placeholder='Phone' className='bg-white text-[20px] flex flex-col items-start text-[] h-[56px] w-full rounded-[10px]' type="text" />
                        <textarea required name="details" placeholder='Additional Details!' className='bg-white min-h-[150px] text-[20px] flex flex-col items-start text-[] h-[56px] w-full rounded-[10px]' id=""></textarea>
                        <button className='bg-[#F2C94C] text-[#191A19] w-full h-[56px] flex flex-col items-center justify-center rounded-[10px]'>Submit Request</button>
                    </form>
                </div>
                <div className='md:w-[450px] border border-white/10 w-full h-full p-[40px]  bg-[#191A19]/90 lg:bg-[#191A19]/60 backdrop-blur-md rounded-[8px] flex flex-col items-start'>
                    <div className='flex flex-col items-start justify-center space-y-[24px] gap-y-[36px]'>
                        <p className='text-[20px] text-white title'>Contact Info</p>
                        <div className='flex flex-col items-start gap-y-1'>
                            <p className='title text-[14px] text-white'>Our Location</p>
                            <p className='text-left text-[16px] text-white'>8735 dunwoody place <br /> Atlanta GA 30350</p>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <p className='title text-[14px] text-white'>Quick Contact</p>
                            <p className='text-left text-[16px] text-white'>Email: sales@monumentconstructionco.com</p>
                            <p className='text-left text-[16px] text-white'>Phone: 404.912.8516</p>
                        </div>

                        <p className='text-[20px] text-white title text-left'>Do You Have Any Question, Just Contact Us To Get Help!</p>
        
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Contact