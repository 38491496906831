import React from 'react'

function SubFooter() {
  return (
    <div className='relative'>
        <img className='bottom-0 left-0 absolute z-0 max-h-[380px]' src="/images/yellow.png"/>
    <div className='flex max-w-[1400px] items-center mx-auto flex-col-reverse px-4 lg:flex-row justify-between pb-[80px] pt-[80px] mx-auto'>
    {/* <img className=' xl:max-w-[40%] relative z-10' src="/images/exp-logo.png"/> */}

    <video controls autoPlay className='xl:max-w-[50%] xl:mr-8 relative z-10'>
      <source  src="/images/VIDEO.mp4" type="video/mp4"/>
    </video>
    
        <div className='flex flex-col  item-center pb-[80px]'>
        <p className='text-[#F2C94C] text-[14px] text-center lg:text-left mb-[12px]'>WHY US</p>
            <h6 className='text-[40px]  2xl:text-[40px] text-[#191A19] mb-[24px] font-extrabold text-center lg:text-left title 50px'><span className='text-[#F2C94C]'>25+ Years</span> <br /> of experience!</h6>
            <p className='text-[16px] mx-auto max-w-[700px] 2xl:text-[20px] text-[#191A19] font-medium text-center lg:text-left'>We have a team of experienced professionals who have been in the industry for over 25 years. Our contractors have a wealth of knowledge and skills that they have acquired over the years, making them experts in their field.</p>
            {/* <p className='text-[16px] max-w-[500px] 2xl:text-[20px] text-[#191A19] font-medium text-left'>With 25 years of experience, our contractors have a deep understanding of industry standards and regulations. We ensure that all our projects comply with the latest safety and building codes, and that the final product meets or exceeds our client's expectations.</p> */}
        </div>
    </div>
</div>
  )
}

export default SubFooter