import React from 'react'

function About() {
  return (
    <div id="about" className='py-[85px] lg:py-[120px] 2xl:max-w-[1400px] gap-y-[50px] mx-auto px-4 flex flex-col lg:flex-row justify-between items-center'>
        <div className='flex flex-col items-center lg:items-start'>
            <p className='text-[#F2C94C] text-[14px] mb-[12px]'>ABOUT US</p>
            <h6 className='title text-[48px] text-[#191A19] mb-[25px] text-center lg:text-left'>What we’re about</h6>
            <h6 className='title text-[14px] text-[#191A19] mb-[25px] text-center lg:text-left'>Welcome to Monument Construction Co, where dreams become reality! With a passion for precision and an eye for detail, we specialize in new construction, remodels, and additions that transform spaces and elevate lifestyles. Our team of skilled professionals is dedicated to delivering exceptional craftsmanship and innovative solutions tailored to your unique vision. </h6>
            <p className='text-[14px] xl:text-[17px] leading-[1.55]    text-center lg:text-left xl:max-w-[560px] text-[#41444B] items-center lg:text-left font-bold'>Whether you're envisioning a brand-new custom home, revitalizing your current space, or expanding to meet your growing needs, we are committed to excellence in every project we undertake. At Monument Construction Co, we don't just build structures; we create lasting impressions and spaces where memories are made. Discover the difference with us and let’s build something extraordinary together.
</p>
        </div>
        <div className='flex flex-col items-center justify-center'>
        <img className='lg:max-w-[640px]' src="/images/about-img.png"/>
        <a target='_blank' href="https://www.2-10.com/" className='flex flex-col md:flex-row w-full items-center md:items-end gap-x-2'>
        <img className='max-w-[120px] mb-2' src="/images/210.svg"/>
        <p className='text-left text-xs lg:text-sm'>We are 2-10 Home Builders Warranty Providers</p>
        </a>
        </div>

    </div>
  )
}

export default About